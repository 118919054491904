import moment from 'moment'
import companyService from '@/service/company'
import * as _ from 'lodash'
import ErrorMessage from '../../data/error'

const state = {
	currentTime: moment().format('DD/MM/YYYY HH:mm:ss'),
	companyConfig: {},
	that: null,
	errMsg: ErrorMessage,
	loading: true
}
const mutations = {
	setCurrentTime: (state, payload) => {
		state.currentTime = moment(payload).format('DD/MM/YYYY HH:mm:ss')
	},
	setCompanyConfig: (state, payload) => {
		state.companyConfig = payload
	},
	setThat: (state, payload) => {
		state.that = payload
	},
	setLoading: (state, payload) => {
		state.loading = payload
	}
}
const actions = {
	SET_CURRENT_TIME: (context) => {
		setInterval(() => {
			context.commit('setCurrentTime', moment())
		}, 1000)
	},
	SET_COMPANY_CONFIG: (context) => {
		context.commit('setLoading', true)
		return companyService.getCompanyConfig().then((resp) => {
			context.commit('setCompanyConfig', resp.data)
			return true
		}).catch(() => {
			return false
		}).finally(() => {
			context.commit('setLoading', false)
		})
	},
	SET_THAT: (context, payload) => {
		context.commit('setThat', payload)
	},
	ALERT_MESSAGE: (context, payload) => {
		const { subject, responseMsg, title } = payload
		const [code, message] = _.split(responseMsg, '|')
		context.state.that.$swal({
			title: title ? title : 'บันทึกข้อมูลผิดพลาด',
			text: context.getters.errMessage(subject, code, message),
			icon: 'error'
		})
		return false
	},
	ALERT_SUCCESS: (context, title) => {
		context.state.that.$swal({
			title: title ? title : 'บันทึกข้อมูลสำเร็จ',
			icon: 'success'
		})
		return true
	},
	SET_LOADING: (context, loaded) => {
		const timeOut = loaded ? 200 : 2000
		setTimeout(() => {
			context.commit('setLoading', loaded)
		}, timeOut)
	}
}

const getters = {
	loading: state => state.loading,
	currentTime: state => state.currentTime,
	calendarId: state => _.get(state.companyConfig, 'currentCalendarId'),
	companyConfig: state => state.companyConfig,
	that: state => state.that,
	errMessage: state => (subject, code, defaultMsg = '') => _.get(state.errMsg, `${subject}.${code}`, defaultMsg === '' ? 'พบข้อผิดพลาด กรุณาติดต่อ admin' : defaultMsg),
}


export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
