import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Breadcrumbs from './components/bread_crumbs'
import VueFeather from 'vue-feather'
import VueSweetalert2 from 'vue-sweetalert2'
import router from './router'
import { Icon } from 'leaflet'

delete Icon.Default.prototype._getIconUrl

import '@/assets/css/color-1.css'
import '@/assets/scss/app.scss'
import 'sweetalert2/dist/sweetalert2.min.css'
import store from './store'

Icon.Default.mergeOptions({
	iconRetinaUrl: require('./assets/images/vue2leaflet/marker-icon.png'),
	iconUrl: require('./assets/images/vue2leaflet/marker-icon.png')
})

// Import vue select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Toasted from 'vue-toasted'

import Multiselect from 'vue-multiselect'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/th';

Vue.component('MultiSelect', Multiselect)

Vue.component('v-select', vSelect)

Vue.component('date-picker', DatePicker)

import 'cropperjs/dist/cropper.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSocketIO from 'vue-socket.io'

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.component('bread-crumbs', Breadcrumbs)
Vue.use(VueFeather)
Vue.use(VueSweetalert2)
Vue.use(Toasted, {
	iconPack: 'fontawesome'
})

import SocketIOClient from 'socket.io-client'

Vue.use(new VueSocketIO({
	debug: true,
	connection: SocketIOClient(process.env.VUE_APP_API_ENDPOINT, {
		transportOptions: {
			polling: {
				extraHeaders: {
					Authorization: `bearer ${ localStorage.getItem('access_token') }`
				}
			}
		}
	}),
}))

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
