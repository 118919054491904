import axios, { Axios } from 'axios'
import * as _ from 'lodash'
import lsService from '@/service/local-storage'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

const instance = axios.create({})


instance.interceptors.request.use(config => {
	const accessToken = lsService.getAccessToken()
	config.headers.Authorization = `Bearer ${accessToken}`
	return config
}, error => {
	return Promise.reject(error);
})

instance.interceptors.response.use(response => response, async error => {
	const status = error.response ? error.response.status : null
	const originalRequest = error.config;
	const refreshToken = localStorage.getItem('refresh_token')
	const ignorePath = ['auth/login']
	if (status === 401 && !originalRequest._retry && !_.includes(ignorePath, error.response.config.url)) {
		originalRequest._retry = true;
		const tempAxios = new Axios({
			baseURL: process.env.VUE_APP_API_ENDPOINT,
		})

		return tempAxios.patch('/auth/refresh', {
			refreshToken
		}).then((resp) => {
			lsService.setTokenFromLogin(resp)
			return instance(originalRequest)
		}).catch((err) => {
			lsService.clearToken()
			return Promise.reject(err);
		})

	}
	return Promise.reject(error);
})

export default instance
