import customerService from '@/service/customer'
import * as _ from 'lodash'

const state = {}
const mutations = {}
const actions = {
	GET_CUSTOMER_LIST(context, payload) {
		return customerService.getCustomerList()
	},
	CREATE_CUSTOMER(context, payload) {
		return customerService.createCustomer(payload).then(() => {
			return context.dispatch('system/ALERT_SUCCESS', null, { root: true })
		}).catch((err) => {
			const payload = {
				subject: 'customer',
				responseMsg: _.get(err, 'response.data.message', '')
			}
			return context.dispatch('system/ALERT_MESSAGE', payload, { root: true })
		})
	},
	UPDATE_CUSTOMER(context, data) {
		const { id, payload } = data
		return customerService.updateCustomer(id, payload).then(() => {
			return context.dispatch('system/ALERT_SUCCESS', 'แก้ไขข้อมูลสำเร็จ', { root: true })
		}).catch((err) => {
			const payload = {
				subject: 'customer',
				responseMsg: _.get(err, 'response.data.message', '')
			}
			context.dispatch('system/ALERT_MESSAGE', payload, { root: true })
			return false
		})
	},
	DELETE_CUSTOMER(context, id) {
		return customerService.deleteCustomer(id).then(() => {
			return context.dispatch('system/ALERT_SUCCESS', 'ลบข้อมูลสำเร็จ', { root: true })
		}).catch((err) => {
			const payload = {
				subject: 'customer',
				responseMsg: _.get(err, 'response.data.message', '')
			}
			context.dispatch('system/ALERT_MESSAGE', payload, { root: true })
			return false
		})
	},
	CUSTOMER_INFO (context, id) {
		return customerService.getCustomerById(id).then((resp) => {
			return resp
		}).catch((err) => {
			const payload = {
				subject: 'customer',
				responseMsg: _.get(err, 'response.data.message', '')
			}
			context.dispatch('system/ALERT_MESSAGE', payload, { root: true })
			return false
		})
	}
}
const getters = {}


export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
