/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Body from '@/components/body'
import store from '@/store'
import * as _ from 'lodash'
import lsService from '@/service/local-storage'

Vue.use(VueRouter)

// const publicPaths = [ 'login' ]

const routes = [
	{
		path: '/',
		name: 'home',
		component: Body,
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../pages/login')
	},
	{
		path: '/bill',
		component: Body,
		children: [
			{
				path: 'create',
				name: 'create-bill',
				component: () => import('../pages/bill/create'),
				meta: {
					requiresAuth: true,
					roles: ['host', 'agent']
				},
			},
			{
				path: 'stuck',
				name: 'stuck-bill',
				component: () => import('../pages/bill/stuck'),
				meta: {
					requiresAuth: true,
					roles: ['host']
				},
			}
		]
	},
	{
		path: '/report',
		component: Body,
		children: [
			{
				path: 'summary',
				name: 'summary-report',
				component: () => import('../pages/report/summary'),
				meta: {
					requiresAuth: true,
					roles: ['host']
				},
			},
			{
				path: 'realtime',
				name: 'realtime-report',
				component: () => import('../pages/report/realtime'),
				meta: {
					requiresAuth: true,
					roles: ['host']
				},
			},
			{
				path: 'customer',
				name: 'customer-report',
				component: () => import('../pages/report/customer'),
				meta: {
					requiresAuth: true,
					roles: ['host']
				},
			}
		]
	},
	{
		path: '/admin',
		component: Body,
		children: [
			{
				path: '',
				name: 'admin-page',
				component: () => import('../pages/admin/index'),
				meta: {
					requiresAuth: true,
					roles: ['administrator']
				},
			}
		]
	},
	{
		path: '/config',
		component: Body,
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'config-page',
				component: () => import('../pages/config/index')
			}
		]
	},
	{
		path: '/logout',
		name: 'logout',
		component: () => import('../pages/logout')
	},
	{
		path: '*',
		component: () => import(`../pages/errors/error_404`)
	}
]


const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes,
	linkActiveClass: "active",
	scrollBehavior () {
		return { x: 0, y: 0 }
	}
})
router.beforeEach((to, from, next) => {
	if (_.get(to.meta, 'title')) {
		document.title = to.meta.title
	} else {
		document.title = 'Double Lucky'
	}
	if (lsService.sessionExpired()) {
		lsService.clearToken()
		if (to.name === 'login') {
			next()
			return
		}
		next('/login')
		return
	}
	if (_.get(to.meta, 'requiresAuth')) {
		const menuRole = _.get(to.meta, 'roles')
		const dataRole = _.get(lsService.getAccessData(), 'roles', '')
		if (!_.includes(menuRole, dataRole)) {
			next('/permission-denied')
			return
		}
		next()
		return
	}
	if (to.name === 'login') {
		next('/logout')
		return
	}
	next()
})

export default router
