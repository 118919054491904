import base from '@/service/axios'

const resourcePath = 'admin'

export default {
	getCompanyList(page = 1, limit = 10) {
		return base.get(`${ resourcePath }/company`, {
			page,
			limit
		}).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	getUserList(page = 1, limit = 1) {
		return base.get(`${ resourcePath }/user`, {
			page,
			limit
		}).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	createCompany(body) {
		return base.post(`${ resourcePath }/company`, body).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	createUser(body) {
		return base.post(`${ resourcePath }/user`, body).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	}
}
