import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import layout from './modules/layout'
import menu from './modules/menu'
import admin from './modules/admin'
import customer from './modules/customer'
import company from './modules/company'
import bill from './modules/bill'
import system from './modules/system'
import socket from './modules/socket'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		user,
		layout,
		menu,
		admin,
		customer,
		bill,
		system,
		socket,
		company
	},
	strict: false
})

export default store
