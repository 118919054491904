import * as _ from 'lodash'
import * as jwt from 'jose'
import * as moment from 'moment'

export default {
	setTokenFromLogin(respData) {
		const accessToken = _.get(respData, 'data.data.access_token')
		const refreshToken = _.get(respData, 'data.data.refresh_token')
		localStorage.setItem('access_token', accessToken)
		localStorage.setItem('refresh_token', refreshToken)
	},
	clearToken() {
		localStorage.clear()
	},
	getAccessToken() {
		return localStorage.getItem('access_token')
	},
	getRefreshToken() {
		return localStorage.getItem('refresh_token')
	},
	getDataPayload() {
		return {
			access_token: this.getAccessToken(),
			refresh_token: this.getRefreshToken()
		}
	},
	sessionExpired() {
		if (_.isNil(this.getAccessToken())) {
			return true
		}
		const expToken = jwt.decodeJwt(this.getAccessToken()).exp
		return moment().unix() > expToken
	},
	getAccessData() {
		return jwt.decodeJwt(this.getAccessToken())
	}
}
