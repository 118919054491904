import companyService from '@/service/company'
import * as _ from 'lodash'

const state = {}
const mutations = {}
const actions = {
	CREATE_CALENDAR: (context, payload) => {
		return companyService.createCalendar(payload)
			.then((resp) => {
				return context.dispatch('system/ALERT_SUCCESS', null, {root: true})
			}).catch((err) => {
				const payload = {
					subject: 'calendar',
					responseMsg: _.get(err, 'response.data.message', '')
				}
				return context.dispatch('system/ALERT_MESSAGE', payload, {root: true})
			})
	},
	CLOSE_CALENDAR: (context, id) => {
		return companyService.closeCalendar(id)
			.then((resp) => {
				return context.dispatch('system/ALERT_SUCCESS', null, {root: true})
			}).catch((err) => {
				const payload = {
					subject: 'calendar',
					responseMsg: _.get(err, 'response.data.message', '')
				}
				return context.dispatch('system/ALERT_MESSAGE', payload, {root: true})
			})
	},
	ADD_PRIZE: (context, payload) => {
		const calendarId = _.get(payload, 'id')
		const data = _.get(payload, 'data')
		return companyService.addPrize(calendarId, data)
			.then((resp) => {
				return context.dispatch('system/ALERT_SUCCESS', null, {root: true})
			}).catch((err) => {
				const payload = {
					subject: 'prize',
					responseMsg: _.get(err, 'response.data.message', '')
				}
				return context.dispatch('system/ALERT_MESSAGE', payload, {root: true})
			})
	},
	UPDATE_CALENDAR: (context, payload) => {
		const calendarId = _.get(payload, 'id')
		const data = _.get(payload, 'data')
		return companyService.updateCalendar(calendarId, data)
			.then((resp) => {
				return context.dispatch('system/ALERT_SUCCESS', null, {root: true})
			}).catch((err) => {
				const payload = {
					subject: 'calendar',
					responseMsg: _.get(err, 'response.data.message', '')
				}
				return context.dispatch('system/ALERT_MESSAGE', payload, {root: true})
			})
	}
}
const getters = {}


export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
