import base from '@/service/axios'

const resourcePath = 'report'

export default {
	getSummaryReport(calendarId) {
		return base.get(`${ resourcePath }/summary?calendarId=${ calendarId }`).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	getRealtimeReport() {
		return base.get(`${ resourcePath }/realtime`).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	getBillDetail(bodyQuery) {
		return base.post(`${ resourcePath }/detail`, bodyQuery).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	getSummaryCustomer(calendarId, customerId) {
		const queryString = new URLSearchParams({
			calendarId, customerId
		}).toString()
		return base.get(`${ resourcePath }/customer?${queryString}`).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	}
}
