<template>
	<div id="app">
		<div class="loader-wrapper"  :class="{'loderhide' : !isLoading }">
			<div class="loader bg-white">
				<div class="whirly-loader" />
			</div>
		</div>
		<Body v-if="isAuth">
			<router-view/>
		</Body>
		<router-view v-else/>
	</div>
</template>
<script>
import Body from '@/components/body'
export default {
	data(){
		return{
			show: true
		}
	},
	components: {
		Body
	},
	computed: {
		isAuth() {
			return this.$store.state.authenticated
		},
		isLoading() {
			return this.$store.getters['system/loading']
		}
	},
	mounted() {
		this.timeOut();
		this.$store.dispatch('system/SET_CURRENT_TIME')
		this.$store.dispatch('system/SET_THAT', this)
		this.$store.dispatch('SETUP_USER')
		this.$store.dispatch('system/SET_COMPANY_CONFIG')
	},
	methods:{
		timeOut(){
			setTimeout(() => {
				this.$store.dispatch('system/SET_LOADING', false)
			}, 1000);
		}
	}
}
</script>
<style scoped src="./assets/css/color-1.css"></style>
<style lang="scss" scoped>
.loader-wrapper.loderhide {
	display: none;
}
</style>

