import authService from '@/service/auth'
import lsService from '@/service/local-storage'

const state = {
	token: null,
	user: null,
	authenticated: false,
	role: '',
	companyId: '',
	socketKey: '',
	profile: null
}

const mutations = {
	setUser(state, payload) {
		state.token = payload.token
		state.user = payload.user
		state.authenticated = true
		state.companyId = payload.companyId
		state.socketKey = payload.socketKey
		state.role = payload.roles
		state.profile = {
			firstname: payload.firstname,
			lastname: payload.lastname,
			uid: payload.uid,
		}
	},
	setAuth(state, payload) {
		state.authenticated = payload
	}
}

const actions = {
	LOGIN(context, payload) {
		return authService.login(payload).then(response => {
			context.commit('setUser', lsService.getAccessData())
			context.commit('setAuth', true)
			return response
		})
	},
	SETUP_USER(context) {
		if (!lsService.sessionExpired()) {
			context.commit('setUser', lsService.getAccessData())
		}
	},
	LOGOUT(context) {
		lsService.clearToken()
		context.commit('setAuth', false)
	}
}

const getters = {
	isAuthenticated: state => {
		return state.authenticated
	},
	role: state => state.role,
	socketKey: state => state.socketKey,
	userInfo: state => state,
	userProfile: state => state.profile,
}


export default {
	state,
	mutations,
	actions,
	getters
}
