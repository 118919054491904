import _ from 'lodash'
import reportService from '@/service/report'
import CryptoJS from 'crypto-js'

function sumGroupData(value, groupBy, sumBy) {
	return _(value).groupBy(groupBy).map((v, k) => ({
		digit: k,
		sumPrice: _.sumBy(v, sumBy)
	})).value()
}

const state = {
	'3-digit-top': [],
	'3-digit-top-swap': [],
	'3-digit-down': [],
	'2-digit-top': [],
	'2-digit-down': [],
	allNumber: [],
	allAmount: 0,
	sumCustomer: []
}
const mutations = {
	initialData: (state) => {
		state.sumCustomer = []
		state.allAmount = 0
		state.allNumber = []
		state['3-digit-top'] = []
		state['3-digit-top-swap'] = []
		state['3-digit-down'] = []
		state['2-digit-top'] = []
		state['2-digit-down'] = []
	},
	newDataDigit: (state, payload) => {
		const typeData = _.get(payload, 'type')
		const tempData = state[typeData]
		state[typeData] = []
		state[typeData] = _.concat(tempData, _.get(payload, 'value'))
	},
	setDataDigit: (state, payload) => {
		const typeData = _.get(payload, 'type')
		state[typeData] = _.get(payload, 'value')
	},
	setAllNumber: (state, payload) => {
		state.allNumber = payload
	},
	addAllNumber: (state, payload) => {
		state.allNumber = _.concat(state.allNumber, payload)
	},
	setAllAmount: (state, payload) => {
		state.allAmount = payload
	},
	addAllAmount: (state, payload) => {
		state.allAmount += payload
	},
	setSumCustomer: (state, payload) => {
		state.sumCustomer = payload
	},
	addSumCustomer: (state, payload) => {
		const sumPrice = _.get(payload, 'sum', 0)
		const customerId = _.get(payload, 'customerId')
		const customerName = _.get(payload, 'customerName')
		const oldData = _.cloneDeep(state.sumCustomer)
		state.sumCustomer = []
		const cusIndex = _.findIndex(oldData, [ 'id', customerId ])
		if (cusIndex >= 0) {
			oldData[cusIndex].sumAmount += sumPrice
		} else {
			oldData.push({
				id: customerId,
				name: customerName,
				sumAmount: sumPrice
			})
		}
		state.sumCustomer = oldData

	}
}
const actions = {
	SETUP_NEWDATA(context, payload) {
		try {
			const convertData = JSON.parse(payload)
			const billDetail = _.get(convertData, 'detail', [])
			const payloadCustomer = {
				sum: _.sumBy(billDetail, 'price'),
				customerId: _.get(convertData, 'customerId'),
				customerName: _.get(convertData, 'customerName'),
			}
			context.commit('addAllNumber', billDetail)
			context.commit('addAllAmount', payloadCustomer.sum)
			context.commit('addSumCustomer', payloadCustomer)
			context.dispatch('SORT_DATA_OBJ')
		} catch (e) {
			console.log('error realtime payload', payload)
		}
	},
	NEW_SOCKET_REALTIME(context, payload) {
		try {
			const secretKey = context.rootGetters.socketKey
			const dataDecrypt = CryptoJS.AES.decrypt(payload, secretKey).toString(CryptoJS.enc.Utf8)
			context.dispatch('SETUP_NEWDATA', dataDecrypt)
		} catch (e) {
			console.log('SecretKey not match', context.rootGetters.socketKey)
		}

	},
	LOAD_REALTIME_DATA(context) {
		context.commit('initialData')
		let allNumber = []
		reportService.getRealtimeReport().then(resp => {
			const reduce = _.reduce(resp.data, (acc, value) => {
				const customerId = _.get(value, 'customer.id')
				if (_.isNil(acc[customerId])) {
					Object.assign(acc, {
						[customerId]: {
							id: customerId,
							name: _.get(value, 'customer.name'),
							sumAmount: _.toNumber(_.get(value, 'amount', 0))
						}
					})
				} else {
					acc[customerId].sumAmount = acc[customerId].sumAmount + _.toNumber(_.get(value, 'amount', 0))
				}
				allNumber = _.concat(allNumber, _.get(value, 'billDetail'))
				return acc
			}, {})
			const arrayReduce = Object.values(reduce)
			context.commit('setSumCustomer', arrayReduce)
			context.commit('setAllNumber', _.cloneDeep(allNumber))
			context.dispatch('SORT_DATA_OBJ')
			context.commit('setAllAmount', _.sumBy(arrayReduce, 'sumAmount'))
		})
	},
	SORT_DATA_OBJ(context) {
		const groupByType = _.groupBy(context.state.allNumber, 'type')
		_.forEach(groupByType, (value, type) => {
			let sumDigit = []
			if (type === '3-digit-top') {
				const data3DigitGroup = _.groupBy(value, 'priceType')
				sumDigit = sumGroupData(_.get(data3DigitGroup, 'direct', []), 'digit', 'price')
				const dataSwap = sumGroupData(_.get(data3DigitGroup, 'swap', []), 'digit', 'price')
				const dataReduceSwap = _.reduce(dataSwap, (acc, item) => {
					const numberKey = _.get(item, 'digit')
					const tempSumPrice = _.get(item, 'sumPrice', 0)
					const arrNum = Array.from(numberKey)
					const lowerNum = _.join(_.sortBy(arrNum), '')
					const tempKey = `_${lowerNum}`
					if(_.isNil(acc[tempKey])) {
						Object.assign(acc, {
							[tempKey]: {
								digit: lowerNum,
								sumPrice: tempSumPrice
							}
						})
					} else {
						acc[tempKey]['sumPrice'] += tempSumPrice
					}
					return acc
				}, {})
				context.commit('setDataDigit', {
					type: '3-digit-top-swap',
					value: Object.values(dataReduceSwap)
				})
			} else {
				sumDigit = sumGroupData(value, 'digit', 'price')
			}
			context.commit('setDataDigit', {
				type,
				value: sumDigit
			})
			// console.log('clone', JSON.stringify(sumDigit))
			// const orderResult = _.orderBy(_.cloneDeep(sumDigit), ['sumPrice'], ['DESC'])
			// console.log('sumDigit', type, orderResult)
		})
	}

}
const getters = {
	realTimeData: state => (type) => _.get(state, type),
	realTimeAllAmount: state => state.allAmount,
	realTimeSumCustomer: state => state.sumCustomer
}


export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
