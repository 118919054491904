import * as _ from 'lodash'

const resourcePath = 'auth'
import base from '@/service/axios'
import lsService from '@/service/local-storage'
import * as jwt from 'jose'

export default {
	login(payload) {
		return base.post(`${ resourcePath }/login`, {
			username: payload.username,
			password: payload.password
		}).then(resp => {
			lsService.setTokenFromLogin(resp)
			const accessToken = _.get(resp, 'data.data.access_token')
			return jwt.decodeJwt(accessToken)
		}).catch(err => {
			throw err
		})
	}
}
