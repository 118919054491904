<template>
	<div>
		<footer class="footer">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-6 footer-copyright">
						<p class="mb-0">Copyright 2022 © Double lotto</p>
					</div>
					<div class="col-md-6">
						<p class="pull-right mb-0">Contact admin Line: @support-lotto<i class="fa fa-heart"></i></p>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	export default {
		name: 'FooterPage',
	}
</script>
