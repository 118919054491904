import base from '@/service/axios'

const resourcePath = 'company'

export default {
	getCompanyConfig() {
		return base.get(`${resourcePath}/config`).then((resp) => {
			return resp.data
		})
	},
	getCompanyCalendar(all= 0) {
		return base.get(`${resourcePath}/calendar?all=${all}`).then((resp) => {
			return resp.data
		})
	},
	createCalendar(payload) {
		return base.post(`${resourcePath}/calendar`, payload).then((resp) => {
			return resp.data
		})
	},
	updateCalendar(id, payload) {
		return base.patch(`${resourcePath}/calendar/${id}`, payload).then((resp) => {
			return resp.data
		})
	},
	closeCalendar(id) {
		return base.delete(`${resourcePath}/calendar/${id}`).then((resp) => {
			return resp.data
		})
	},
	addPrize(calendarId, payload) {
		const mapPayload = {
			prize: payload
		}
		return base.post(`${resourcePath}/prize/${calendarId}`, mapPayload).then((resp) => {
			return resp.data
		})
	}
}
