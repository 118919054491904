import adminService from '@/service/admin'
import * as _ from 'lodash'

const state = {
	companyList: [],
	companyAll: 0,
	customerList: [],
	customerAll: 0
}
const mutations = {
	setCompanyInfo(state, payload) {
		state.companyList = []
		state.companyList = _.get(payload, 'list', [])
		state.companyAll = _.get(payload, 'all', 0)
	},
	setCustomerInfo(state, payload) {
		state.customerList = []
		state.customerList = _.get(payload, 'list', [])
		state.customerAll = _.get(payload, 'all', 0)
	}
}
const actions = {
	GET_COMPANY_LIST(context) {
		return adminService.getCompanyList().then((resp) => {
			context.commit('setCompanyInfo', {
				list: _.get(resp, 'data.rows', []),
				all: _.get(resp, 'data.count', 0)
			})
			return true
		}).catch(() => false)
	},
	GET_USER_LIST(context) {
		return adminService.getUserList().then((resp) => {
			context.commit('setCustomerInfo', {
				list: _.get(resp, 'data.rows', []),
				all: _.get(resp, 'data.count', 0)
			})
			return resp
		}).catch(() => {})
	},
	CREATE_COMPANY(context, data) {
		const that = context.rootGetters['system/that']
		return adminService.createCompany(data)
			.then(() => {
				that.$swal({
					title: 'บันทึกข้อมูลสำเร็จ',
					icon: 'success'
				})
				return true
			})
			.catch(err => {
				const respMeg = _.get(err, 'response.data.message', '')
				const [ code, message ] = _.split(respMeg, '|')
				that.$swal({
					title: 'บันทึกข้อมูลผิดพลาด',
					text: context.rootGetters['system/errMessage']('company', code, message),
					icon: 'error'
				})
				throw false
			})
	},
	CREATE_USER(context, data) {
		const that = context.rootGetters['system/that']
		return adminService.createUser(data)
			.then(() => {
				that.$swal({
					title: 'บันทึกข้อมูลสำเร็จ',
					icon: 'success'
				})
				return true
			})
			.catch(err => {
				const respMeg = _.get(err, 'response.data.message', '')
				const [ code, message ] = _.split(respMeg, '|')
				that.$swal({
					title: 'บันทึกข้อมูลผิดพลาด',
					text: context.rootGetters['system/errMessage']('user', code, message),
					icon: 'error'
				})
				throw false
			})
	}
}
const getters = {
	companyList: state => state.companyList,
	userList: state => state.customerList
}


export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
