<template>
	<div>

		<div class="page-wrapper" :class="layout.settings.sidebar.type">
			<Header @clicked="sidebar_toggle" @rightclicked="right_sidebar_toggle_var" @mobileclicked="mobile_toggle_toggle" />
			<div class="page-body-wrapper" :class="layout.settings.sidebar.body_type">
				<div class="page-sidebar" :class="[{ open : sidebar_toggle_var }, layout.settings.sidebar_backround]" :sidebar-layout="layout.settings.sidebar_setting">
					<Sidebar />
				</div>
<!--				<RightSidebar :rightsidebar_toggle_var = right_sidebar_toggle />-->
				<div class="page-body">
					<transition name="fadeIn" enter-active-class="animated fadeIn">
						<router-view class="view"></router-view>
					</transition>
				</div>
				<Footer/>
			</div>
		</div>
	</div>
</template>

<script>
import Header from './header'
import Sidebar from './sidebar'
import Footer from './footer'
export default {
	name: 'body-page',
	components:{
		Header,
		Sidebar,
		Footer,
	},
	data(){
		return{
			right_sidebar_toggle_var:false,
			mobile_header_toggle_var: false,
			sidebar_toggle_var: false,
			horizontal_Sidebar: true,
			resized:false
		}
	},
	created(){
		window.addEventListener('resize', this.handleResize)
		this.handleResize();
		this.resized = this.sidebar_toggle_var;
		this.$store.dispatch('set')
	},
	computed: {
		menuItems() {
			return this.$store.getters.menuItems
		},
		layout() {
			return this.$store.getters.layout
		}
	},
	methods:{
		sidebar_toggle(value) {
			this.sidebar_toggle_var = !value
		},
		right_sidebar_toggle(value) {
			this.right_sidebar_toggle_var = value
		},
		mobile_toggle_toggle(value) {
			this.mobile_header_toggle_var = value
		},
		handleResize() {
			this.width = window.innerWidth;
			this.height = window.innerHeight;
		}
	}
}
</script>

<style scoped>

</style>
