import base from '@/service/axios'

const resourcePath = 'customer'

export default {
	getCustomerById(id) {
		return base.get(`${ resourcePath }/${id}`).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	getCustomerList() {
		return base.get(`${ resourcePath }/company`).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	createCustomer(payload) {
		return base.post(`${ resourcePath }`, payload).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	updateCustomer(id, payload) {
		return base.patch(`${ resourcePath }/${id}`, payload).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	deleteCustomer(id) {
		return base.delete(`${ resourcePath }/${id}`).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	}
}
