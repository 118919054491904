import billService from '@/service/bill'
import * as _ from 'lodash'

const state = {}
const mutations = {}
const actions = {
	CREATE_BILL(context, payload) {
		const customerId = _.get(payload, 'customerId', 0)
		const numberData = _.get(payload, 'numberData', [])
		const that = context.rootGetters['system/that']
		return billService.createBill(numberData, customerId, context.rootGetters['system/calendarId'])
			.then(() => {
				that.$swal({
					title: 'บันทึกข้อมูลสำเร็จ',
					icon: 'success'
				})
			})
			.catch(err => {
				const respMeg = _.get(err, 'response.data.message', '')
				const [code, message] = _.split(respMeg, '|')
				that.$swal({
					title: 'บันทึกข้อมูลผิดพลาด',
					text: context.rootGetters['system/errMessage']('bill', code, message),
					icon: 'error'
				})
				throw false
			})
	}
}
const getters = {}


export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
