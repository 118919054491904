import base from '@/service/axios'
import * as _ from 'lodash'

const resourcePath = 'bill'

export default {
	createBill(data, customerId, calendarId) {
		let sumPrice = 0
		const numberData = _.map(data, (v) => {
			const price_direct = _.get(v, 'price_direct', 0)
			const price_swap = _.get(v, 'price_swap', 0)
			const schema = _.get(v, 'schema', 2)
			const suffix = _.get(v, 'suffix', 2)
			sumPrice += price_direct + price_swap
			return {
				number: _.get(v, 'no'),
				price: price_direct > 0 ? price_direct : price_swap,
				type: `${ schema }-digit-${ suffix }`,
				isSwap: price_swap > 0
			}
		})
		const payload = {
			customerId,
			calendarId,
			amount: sumPrice,
			billName: '',
			numberData
		}
		return base.post(`${ resourcePath }`, payload).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	getBillStuck() {
		return base.get(`${ resourcePath }/stuck`).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	},
	getDetailBill(id) {
		return base.get(`${ resourcePath }/${ id }`).then(resp => {
			return resp.data
		}).catch(err => {
			throw err
		})
	}
}
